import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface CartOption {
  id: number;
  value: number | string;
  groupTitle: string;
  price?: number;
}

interface CartProduct {
  variantId: number;
  options?: CartOption[];
}

interface ConstructItem {
  product: CartProduct;
  diamondId: number;
}

interface DiamondItem {
  diamondId: number;
}

interface ProductItem {
  product: CartProduct;
}

export type CartItem = { id: string } & (
  | ConstructItem
  | DiamondItem
  | ProductItem
);

interface State {
  open: boolean;
  setOpen: (open: boolean) => void;

  openWarningModal: boolean;
  setOpenWarningModal: (open: boolean) => void;

  list: CartItem[];

  addItem: (data: ConstructItem | DiamondItem | ProductItem) => void;
  removeItem: (id: string) => void;
  removeAll: () => void;
}

const useShopingCart = create<State>()(
  persist(
    (set) => ({
      open: false,
      setOpen(open) {
        set({ open });
      },

      openWarningModal: false,
      setOpenWarningModal(open) {
        set({ openWarningModal: open });
      },

      list: [],
      addItem(data) {
        set((state) => {
          if (
            'diamondId' in data &&
            state.list.find(
              (item) =>
                'diamondId' in item && item.diamondId === data.diamondId,
            )
          ) {
            return { openWarningModal: true };
          }
          const id = uuidv4();

          if ('product' in data) {
            const isPresetRing = Boolean(
              data?.product?.options?.find(
                ({ groupTitle }) => groupTitle === 'Carat Weight',
              ),
            );

            if (isPresetRing) {
              return {
                list: [
                  { id, ...data },
                  ...state.list.filter((item) =>
                    'product' in item
                      ? !(item?.product?.variantId === data?.product?.variantId)
                      : true,
                  ),
                ],
                open: true,
              };
            }
          }

          return {
            list: [{ id, ...data }, ...state.list],
            open: true,
          };
        });
      },

      removeItem(id) {
        set((state) => ({ list: state.list.filter((item) => item.id !== id) }));
      },

      removeAll() {
        set({ list: [] });
      },
    }),
    {
      name: 'shoping-cart',
    },
  ),
);

export default useShopingCart;
