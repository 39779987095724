import { useQuery } from '@tanstack/react-query';

import {
  getCategoryAttributes,
  getCategoryList,
  getMetalGroups,
  getMetals,
  getProduct,
  getProductVariantList,
  getPromotions,
  getRanges,
  getReviews,
} from '@/api/product';
import { serializeAndStringifyParams } from '@/helpers/general';

const keys = {
  trends: 'product/trends',
  newCollection: 'product/newCollection',
  perfectPair: 'product/perfectPair',
  list: 'product/list',
  single: 'product/single',
  categories: 'product/categories',
  categoryAttributes: 'product/category-attributes',
  reviews: 'product/reviews',
  metals: 'product/metals',
  metalGroups: 'product/metalGroups',
  ranges: 'product/ranges',
  promotions: 'product/promotions',
};

export const trendsQueryCreator = () => ({
  queryKey: [keys.trends],
  queryFn: () => getProductVariantList({ trendNow: 1 }),
});

export const newCollectionQueryCreator = () => ({
  queryKey: [keys.newCollection],
  queryFn: () => getProductVariantList({ newCollection: 1 }),
});

export const perfectPairQueryCreator = (
  productSlug: string,
  options?: { throttleKey?: string },
) => ({
  queryKey: [keys.perfectPair, productSlug],
  queryFn: () =>
    getProductVariantList(
      {
        perfectProductSlug: productSlug,
        perPage: 100,
      },
      options,
    ),
});

export const productListQueryCreator = (
  params?: Parameters<typeof getProductVariantList>[0],
) => ({
  queryKey: [keys.list, serializeAndStringifyParams(params || {})],
  queryFn: (data?: { pageParam?: number | unknown }) =>
    getProductVariantList(
      params && {
        ...params,
        page:
          (typeof data?.pageParam === 'number' && data?.pageParam) ||
          params.page,
      },
    ),
});

export const productQueryCreator = (
  ...[params, options]: Parameters<typeof getProduct>
) => {
  const queryKey = [keys.single];

  queryKey.push('slug' in params ? params.slug : params.variantId.toString());

  return {
    queryKey,
    queryFn: async () => {
      const response = await getProduct(params, options);
      return {
        data: {
          ...response.data,
          optionsProduct: response.data.optionsProduct.map((item) => ({
            ...item,
            groupedValues: item.groupedValues.sort(
              (a, b) => (a.priority || 0) - (b.priority || 0),
            ),
          })),
        },
      };
    },
  };
};

export const productCategoryListQueryCreator = () => ({
  queryKey: [keys.categories],
  queryFn: async () => {
    const response = await getCategoryList();

    const sortCategories = (
      categaryList: Awaited<typeof response>['data'],
    ): Awaited<typeof response>['data'] =>
      categaryList
        .filter((item) => item.slug)
        .map((item) => ({
          ...item,
          subItems: item.subItems && sortCategories(item.subItems),
        }))
        .sort((a, b) => (a.priority || 0) - (b.priority || 0));

    return {
      data: sortCategories(response.data),
    };
  },
});

export const useDiamondAttributes = () => useQuery(trendsQueryCreator());

export const categoryAttributesQueryCreator = (
  ...[categoryId]: Parameters<typeof getCategoryAttributes>
) => ({
  queryKey: [keys.categoryAttributes, categoryId],
  queryFn: async () => {
    const response = await getCategoryAttributes(categoryId);

    type Item = (typeof response.data)[0];

    const data = response.data.reduce(
      (
        acc: {
          metals: Item[];
          shapes: Item[];
          inStock: Item[];
          band: Item[];
          caratWeight: Item[];
        },
        item,
      ) => {
        if (item.type === 'attribute_value') {
          return { ...acc, shapes: [...acc.shapes, item] };
        }
        if (item.type === 'in_stock') {
          return { ...acc, inStock: [...acc.inStock, item] };
        }
        if (item.type === 'option_value') {
          if (item.option?.slug === 'ring-band') {
            return {
              ...acc,
              band: [...acc.band, item],
            };
          }

          if (item.option?.slug === 'reset-ring-carat-weight') {
            return {
              ...acc,
              caratWeight: [
                ...acc.caratWeight,
                { ...item, title: `${item.title} ct` },
              ],
            };
          }
        }

        return { ...acc, metals: [...acc.metals, item] };
      },
      { metals: [], shapes: [], inStock: [], band: [], caratWeight: [] },
    );
    data.metals.sort((a, b) => (a.priority || 0) - (b.priority || 0));
    data.shapes.sort((a, b) => (a.priority || 0) - (b.priority || 0));
    data.inStock.sort((a, b) => (a.priority || 0) - (b.priority || 0));
    data.band.sort((a, b) => (a.priority || 0) - (b.priority || 0));
    data.caratWeight.sort((a, b) => (a.priority || 0) - (b.priority || 0));

    return { data };
  },
});

export const reviewsQueryCreator = (
  ...[productSlug, params, headers]: Parameters<typeof getReviews>
) => ({
  queryKey: [
    keys.reviews,
    productSlug,
    serializeAndStringifyParams(params || {}),
  ],
  queryFn: async (data?: { pageParam: number }) =>
    getReviews(
      productSlug,
      {
        ...params,
        page: data ? data.pageParam : 1,
      },
      headers,
    ),
});

export const metalsQueryCreator = (
  ...[params]: Parameters<typeof getMetals>
) => ({
  queryKey: [keys.metals, serializeAndStringifyParams(params || {})],
  queryFn: async () => {
    const response = await getMetals(params);

    return {
      data: response.data.sort((a, b) => (a.priority || 0) - (b.priority || 0)),
    };
  },
});

export const metalGroupsQueryCreator = (
  ...[params]: Parameters<typeof getMetalGroups>
) => ({
  queryKey: [keys.metalGroups, serializeAndStringifyParams(params || {})],
  queryFn: async () => {
    const response = await getMetalGroups(params);

    return {
      data: response.data.sort((a, b) => (a.priority || 0) - (b.priority || 0)),
    };
  },
});

export const rangesQueryCreator = () => ({
  queryKey: [keys.ranges],
  queryFn: getRanges,
});

export const promotionsQueryCreator = (
  ...[params]: Parameters<typeof getPromotions>
) => ({
  queryKey: [keys.promotions, serializeAndStringifyParams(params || '')],
  queryFn: () => getPromotions(params),
});
