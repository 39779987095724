import { CartOption } from '@/app/_store/shoping-cart';

const getProductPrice = (
  price: number,
  options?: { [key: string]: number }[] | CartOption[] | null,
  isIncludeSeparateDiamond?: boolean,
) => {
  if (options) {
    return (
      price +
      options.reduce(
        (acc, cur) => {
          const caratWeightPrice = cur.groupTitle === 'Carat Weight';

          return (
            acc +
            // eslint-disable-next-line no-nested-ternary
            (isIncludeSeparateDiamond
              ? cur.groupTitle === 'Carat Weight'
                ? 0
                : cur.price || 0
              : cur.price || 0)
          );
        },

        0,
      )
    );
  }

  return price;
};

export default getProductPrice;
